import { createContext, useContext,useState } from "react";


export const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
// HELPER FUNCTIONS for custom dates 
function formatDate(date) {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        // If a month or day is less than 10, it pads it with a leading '0'
        const day = date.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
      }

 // Today
 const Tday = new Date()
 const todayFormatted = formatDate(Tday)
 const today = { startDate: todayFormatted, endDate: todayFormatted }

 //yesterday***********************************************************
 const yestDay = new Date(Tday)
 yestDay.setDate(Tday.getDate() - 1)
 const yesterdayFormatted = formatDate(yestDay)
 const yesterday = { startDate: yesterdayFormatted, endDate: yesterdayFormatted }

  //Last Week*************************************************************
  const lastWeekStartDate = new Date(Tday)
  lastWeekStartDate.setDate(Tday.getDate() - 6)
  const lastWeekEndDate = new Date(Tday)
  lastWeekEndDate.setDate(Tday.getDate())
  const lastWeekStartFormatted = formatDate(lastWeekStartDate)
  const lastWeekEndFormatted = formatDate(lastWeekEndDate)
  const week = { startDate: lastWeekStartFormatted, endDate: lastWeekEndFormatted }
 
 

 // Current Month***************************************************
 const currentMonthStartDate = new Date(Tday.getFullYear(), Tday.getMonth(), 1)
 const currentMonthEndDate = new Date(Tday.getFullYear(), Tday.getMonth(), Tday.getDate())
 const currentMonthStartFormatted = formatDate(currentMonthStartDate)
 const currentMonthEndFormatted = formatDate(currentMonthEndDate)
 const currentMonth = { startDate: currentMonthStartFormatted, endDate: currentMonthEndFormatted }

 //month ***********************************************************
  const thirtyDaysAgo = new Date(Tday);
  thirtyDaysAgo.setDate(Tday.getDate() - 30);
  const startDateFormatted = formatDate(thirtyDaysAgo);
  const endDateFormatted =   formatDate(Tday);
const month = { startDate: startDateFormatted, endDate: endDateFormatted };


    return (
        <GlobalContext.Provider value={{today,yesterday,currentMonth,week,month}}>
        {children}
        </GlobalContext.Provider>
    )
    
}
export const GlobalContextData = ()=> useContext(GlobalContext);
export default GlobalProvider
