import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom toast notification functions

const notifySuccessCenter = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover:false
  });

}

const notifySuccessRight = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const notifyErrorCenter = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover:false
  });
};

const notifyErrorRight = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const notifyInfoCenter = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover:false
  });
};

const notifyInfoRight = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const notifyWarningCenter = (message) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover:false
  });
};

const notifyWarningRight = (message) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

// Custom toast container component
const CustomToastContainer = () => (
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);
export default CustomToastContainer;
export {
  notifySuccessCenter,
  notifySuccessRight,
  notifyErrorCenter,
  notifyErrorRight,
  notifyInfoCenter,
  notifyInfoRight,
  notifyWarningCenter,
  notifyWarningRight,
};
