import React, { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CustomToastContainer from './components/ToastMessage';

const HomePage = lazy(() => import('./pages/HomePage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const DashBoardTab = lazy(() => import('./pages/dashboard/DashBoardTab'));

const ManageAppsTab = lazy(() => import('./pages/manage-apps/ManageAppsTab'));
const UpdateAppForm = lazy(() => import('./pages/manage-apps/edit-manage-apps/UpdateAppForm'));
const AppsListPage = lazy(() => import('./pages/manage-apps/AppsListPage'));

const ReportsTab = lazy(() => import('./pages/reports/ReportsTab'));
const AnalyticsPage = lazy(() => import('./pages/reports/Analytics'));
const ConversionPage = lazy(() => import('./pages/reports/Conversion'));

const AccountTab = lazy(() => import('./pages/my_account/AccountTab'));
const AccountTabPage = lazy(() => import('./pages/my_account/AccountTabPage'));
const PaymentPage = lazy(() => import('./pages/payments/PaymentPage'));
const PaymentTabPage = lazy(() => import('./pages/my_account/PaymentTabPage'));
const TaxFormTabPage = lazy(() => import('./pages/my_account/TaxFormTabPage'));

const ResetPasswordPage = lazy(() => import('./pages/login_signup/ResetPasswordPage'));
const ForgetPasswordPage = lazy(() => import('./pages/login_signup/ForgetPasswordPage'));
const NewLoginPage = lazy(() => import('./pages/login_signup/NewLoginPage'));
const NewRegisterPage = lazy(() => import('./pages/login_signup/NewRegisterPage'));
const VerifyEmail =	lazy(()=>import('./pages/login_signup/VerifyEmail'))


const router =createBrowserRouter([
	{
	  path: '/',
	  element: (
		<Suspense fallback={null}>
		  <HomePage />
		</Suspense>
	  ),
	  errorElement: (
		<Suspense fallback={null}>
		  <ErrorPage />
		</Suspense>
	  ),
	  children: [
		{
		  path: '/',
		  element: (
			<Suspense fallback={null}>
			  <DashBoardTab />
			</Suspense>
		  ),
		},
		{
		  path: '/payments',
		  element: (
			<Suspense fallback={null}>
			  <PaymentPage />
			</Suspense>
		  ),
		},
		{
		  path: '/manage-apps',
		  element: (
			<Suspense fallback={null}>
			  <ManageAppsTab />
			</Suspense>
		  ),
		  children: [
			{
			  path: '/manage-apps',
			  element: (
				<Suspense fallback={null}>
				  <AppsListPage />
				</Suspense>
			  ),
			},
			{
			  path: '/manage-apps/edit-app/:id',
			  element: (
				<Suspense fallback={null}>
				  <UpdateAppForm />
				</Suspense>
			  ),
			},
		  ],
		},
		{
		  path: '/reports',
		  element: (
			<Suspense fallback={null}><ReportsTab /></Suspense>),
		  children:[
			{
				path:'/reports/analytics',
				element: <Suspense fallback={null}><AnalyticsPage/></Suspense>
			},
			{
				path:'/reports/conversion',
				element: <Suspense fallback={null}><ConversionPage/></Suspense>
			}
		  ]
		},
		{
		  path: '/account',
		  element: (
			<Suspense fallback={null}>
			  <AccountTab />
			</Suspense>
		  ),
		  children: [
			{
			  path: '/account',
			  element: (
				<Suspense fallback={null}>
				  <AccountTabPage />
				</Suspense>
			  ),
			},
			{
			  path: '/account/payment-details',
			  element: (
				<Suspense fallback={null}>
				  <PaymentTabPage />
				</Suspense>
			  ),
			},
			{
			  path: '/account/tax-form',
			  element: (
				<Suspense fallback={null}>
				  <TaxFormTabPage />
				</Suspense>
			  ),
			},
		  ],
		},
	  ],
	},
	{
		path: '/register',
		element: (
		  <Suspense fallback={null}>
			<NewRegisterPage />
		  </Suspense>
		),
	  },
	  {
		  path: '/verifyemail',
		  element: (
			<Suspense fallback={null}>
			  <VerifyEmail />
			</Suspense>
		  ),
		},
	  {
		path: '/login',
		element: (
		  <Suspense fallback={null}>
			<NewLoginPage />
		  </Suspense>
		),
	  },
	  {
		path: '/reset-password/:',
		element: (
		  <Suspense fallback={null}>
			<ResetPasswordPage />
		  </Suspense>
		),
	  },
	  {
		path: '/forgot-password/',
		element: (
		  <Suspense fallback={null}>
			<ForgetPasswordPage />
		  </Suspense>
		),
	  },
  ]);

const App = () => {

	return (
		<Provider store={store}>
			<RouterProvider router={router} />
			<CustomToastContainer/>
		</Provider>
	);
};

export default App;
